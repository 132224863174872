/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          // $(".fancy_title").lettering();




        $(document).on('click', '#menu-item-206 > a ', function(e) {

          window.location.href = "/projects/";
          
        } );


        jQuery(function($) {

// begin dropdown on hover
          function toggleDropdown(e) {
            const _d = $(e.target).closest('.dropdown'),
            _m = $('.dropdown-menu', _d)
            setTimeout(
              function () {
                const shouldOpen = e.type !== 'click' && _d.is(':hover')
                _m.toggleClass('show', shouldOpen)
                _d.toggleClass('show', shouldOpen)
                $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen)
              },
              e.type === 'mouseleave' ? 300 : 0
              )
          }

          $('body')
          .on('mouseenter mouseleave', '.dropdown', toggleDropdown)
          .on('click', '.dropdown-menu a', toggleDropdown)
// end dropdown on hover

  // Bootstrap menu magic
          $(window).resize(function() {
            if ($(window).width() < 768) {
              $(".dropdown-toggle").attr('data-toggle', 'dropdown');
            } else {
              $(".dropdown-toggle").removeAttr('data-toggle dropdown');
            }
          });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },  

    'single_projects': {
      init: function() {
        // JavaScript to be fired on the home page






// begin js only on mobile
        function myFunction(x) {
  if (x.matches) { // If media query matches

    $('#left').collapse()

    console.log("change < 768");
  } else{

 }
}

// Create a MediaQueryList object
var x = window.matchMedia("(max-width: 768px)")

// Call listener function at run time
myFunction(x);

// Attach listener function on state changes
x.addEventListener("change", function() {
  myFunction(x);
});





// end js only on mobile

lightbox.option({
  'resizeDuration': 200,
  'wrapAround': true
});

var $videoSrc;  
$('.video-btn').click(function() {
  $videoSrc = $(this).data( "src" );
});


// when the modal is opened autoplay it  
$('#myModal').on('shown.bs.modal', function (e) {

// set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
  $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
})


// stop playing the youtube video when I close the modal
$('#myModal').on('hide.bs.modal', function (e) {
    // a poor man's stop video
  $("#video").attr('src',$videoSrc);
}) 


},
finalize: function() {
        // JavaScript to be fired on the home page, after the init JS



}
},
    // About us page, note the change from about-us to about_us.
'about_us': {
  init: function() {
        // JavaScript to be fired on the about us page
  }
}
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
      // Fire common init JS
    UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

      // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

  // Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
